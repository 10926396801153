import React, { useState } from 'react';
import {
  BarChartOutlined,
  BellOutlined, 
  BorderOuterOutlined,  
  MessageOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  PieChartOutlined,  
  SettingOutlined,  
  UsergroupAddOutlined,
  UserOutlined, 
} from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Button, Layout, Menu, theme, Avatar, Badge, DatePicker, Space, Dropdown } from 'antd';

const { Header, Sider, Content, Footer } = Layout;

const { RangePicker } = DatePicker;

const App: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'My Account',
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: 'Бүртгэл',
      icon: <UserOutlined />,
    },
    {
      key: '3',
      label: 'Тохиргоо',
      icon: <SettingOutlined />,
    },
    {
      key: '4',
      label: 'Гарах',
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '0',
              icon: <span role="img" aria-label="border-outer" className="anticon anticon-border-outer ant-menu-item-icon">
                <img src={'/logo.png'} width={18} height={18} alt='Лого'/></span>,
              label: 'Бэрх Уул ХК'
            },
            {
              key: '1',
              icon: <BorderOuterOutlined />,
              label: 'Төслүүд',
            },
            {
              key: '2',
              icon: <UsergroupAddOutlined />,
              label: 'Ажилчид',
            },
            {
              key: '3',
              icon: <PieChartOutlined />,
              label: 'Агуулах',
            },
            {
              key: '4',
              icon: <BarChartOutlined />,
              label: 'Борлуулалт',
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0 16px', background: colorBgContainer }}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Space size="large">
            <Badge size="small" count={5} offset={[0, 5]}>
              <BellOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
            </Badge>
            <Badge size="small" count={3} offset={[0, 5]}>
              <MessageOutlined style={{ fontSize: '18px', cursor: 'pointer' }} />
            </Badge>
            <Dropdown menu={{items}} trigger={['click']}>
              <Avatar icon={<UserOutlined />} style={{ cursor: 'pointer' }} />
            </Dropdown>
          </Space>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <RangePicker showTime 
            id={{
              start: 'startInput',
              end: 'endInput',
            }}
            onFocus={(_, info) => {
              console.log('Focus:', info.range);
            }}
            onBlur={(_, info) => {
              console.log('Blur:', info.range);
            }}
          />
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          Бэрх Уул ХК © {new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;